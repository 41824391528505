import styled from "styled-components";

const HeadingSecondaryStyled = styled.div`
  max-width: 1210px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  text-align: center;

  p {
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.25rem;
    font-size: 1.125rem;
    font-weight: 400;
    opacity: 0.6;
    line-height: 140% !important;
  }

  h2 {
    font-size: 2.5rem;
    line-height: 115%;
    font-weight: 700;
  }

  @media (min-width: 768px) {
    h2 {
      font-size: 3.75rem;
      line-height: 1;
    }

    p {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-top: 1.75rem;
    }
  }
`

export default HeadingSecondaryStyled;
