import styled from "styled-components";

const HeadingPrimaryStyled = styled.div`
  margin-top: 28px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  h1 {
    font-size: 2.5rem;
    line-height: 46px;
    font-weight: 700;
  }

  p {
    margin-left: auto;
    margin-right: auto;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
    opacity: 0.6;
    margin-top: 1.75rem;
  }

  @media (min-width: 768px) {
    margin-top: 5rem;
    h1 {
      font-size: 4.375rem;
      line-height: 80.5px;
    }

    p {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
`

export default HeadingPrimaryStyled
