import styled from "styled-components";

const SignupHeader = styled.h2`
  font-size: 1.625rem;
  line-height: 32.89px;
  font-weight: 500;
  @media (min-width: 768px) {
    font-size: 2.5rem;
    line-height: 50.6px;
  }
`

const SignupDescription = styled.p`
  font-size: 1rem;
  line-height: 140%;
  @media (min-width: 768px) {
    font-size: 1.375rem;
  }
`

const SignupStyledButton = styled.button`
  background: linear-gradient(rgb(111, 102, 225) 9.86%, rgb(78, 68, 206) 87.79%);
`


export default function NewsLetter() {
  return (<section
    className="grid items-center max-w-[1210px] mt-20 grid-cols-1 gap-[30px] px-5 py-9 mx-5 rounded-xl bg-ph-purple md:py-[40px] md:mt-lg md:gap-6 md:pl-[40px] md:pr-[50px] md:grid-cols-3">
    <div className="flex flex-col items-center md:space-x-6 md:flex-row md:col-span-2">
      <figure
        className="grid flex-none w-[82px] h-[82px] md:w-[100px] md:h-[100px] rounded-full bg-ph-purple-darker place-content-center">
        <svg viewBox="0 0 35 56" fill="none" className="h-[55px] w-[34px]" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.0351 0L16.6627 1.25878V37.7823L17.0351 38.1521L34.0726 28.1307L17.0351 0Z" fill="white"/>
          <path d="M17.0379 0L0 28.1307L17.0379 38.1521V20.4245V0Z" fill="white"/>
          <path d="M17.0353 41.3633L16.8254 41.618V54.6283L17.0353 55.238L34.0832 31.3472L17.0353 41.3633Z"
                fill="white"/>
          <path d="M17.038 55.238V41.3633L6.10352e-05 31.3472L17.038 55.238Z" fill="white"/>
          <path d="M17.0355 38.1514L34.0729 28.13L17.0355 20.4238V38.1514Z" fill="white"/>
          <path d="M6.10352e-05 28.13L17.038 38.1514V20.4238L6.10352e-05 28.13Z" fill="white"/>
        </svg>
      </figure>
      <div className="mt-4 text-center md:mt-0 md:text-left"><SignupHeader>Join the Newsletter
        beta</SignupHeader><SignupDescription
        className="mt-[2px] opacity-70">Sign
        up to join the
        Newsletter beta waitlist</SignupDescription></div>
    </div>
    <form className="relative ethereum-signup">
      <input type="email" placeholder="Email"
             className="w-full pl-[30px] py-3 bg-black	bg-opacity-30 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-300 focus:ring-opacity-40 transition duration-200 h-[52px]"
      />
      <SignupStyledButton name={`name`}
        className="absolute transform -translate-y-1/2 rounded-full top-1/2 right-2 focus:outline-none">
        <figure className="grid w-10 h-10 place-content-center">
          <svg width={17} height={16} viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
            <path
              d="M16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM0 9H16V7H0V9Z"
              fill="white"/>
          </svg>
        </figure>
      </SignupStyledButton>
    </form>
  </section>)
}
