import HeadingSecondaryStyled from "./styled/HeadingSecondaryStyled";
import styled from "styled-components";


const StyledArticle = styled.article`
  height: 100%;
  padding: 3rem 2.25rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgba(53, 54, 58, var(--tw-bg-opacity));

  h3 {
    font-size: 1.375rem;
    line-height: 140%;
    font-weight: 400;
  }

  p {
    line-height: 140%;
    font-size: 18px;
    color: rgb(174, 175, 176);
    margin-top: 0.75rem;
  }

  @media (min-width: 768px) {
    max-width: 100%;
    max-height: 100%;
  }
`

export default function Features() {
  return (<section className="max-w-[1210px] px-3 mt-[94px] md:mt-lg mx-auto text-center">
    <HeadingSecondaryStyled>
      <h2>All the features you want</h2>
      <p>Build your workflow with our powerful features.</p>
    </HeadingSecondaryStyled>
    <div
      className="grid grid-cols-1 gap-5 px-3 text-left md:gap-10 mt-[49px] md:mt-md sm:grid-cols-2 md:grid-cols-3">
      <StyledArticle><h3>Flexible</h3><p>Covering almost any project scenario, you can build the workflow the way you want.</p></StyledArticle>
      <StyledArticle><h3>Simple</h3><p>Our clean and simple UI makes it easy to understand at a glance.</p></StyledArticle>
      <StyledArticle><h3>Customization</h3><p>Integrates with hundreds of software, so you don't have to change your habits.</p></StyledArticle>
      <StyledArticle><h3>Powerful</h3><p>Content Content Content Content Content</p></StyledArticle>
      <StyledArticle><h3>Efficiently</h3><p>Content Content Content Content Content</p></StyledArticle>
      <StyledArticle><h3>Security</h3><p>Content Content Content Content Content</p></StyledArticle>
    </div>
  </section>);
}
