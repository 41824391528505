import HeadingPrimaryStyled from "./styled/HeadingPrimaryStyled";

export default function (){
  return (<HeadingPrimaryStyled className="px-7 lg:px-0">
    <h1
      className="mx-auto ">Now Keep your work&nbsp;<span className="heading-underline ">organized</span></h1><p
    className="max-w-[308px] sm:max-w-[659px]">We provide highly customized online project management tools to make your work more efficient and save time.</p><a
    href="#" target="_blank"
    rel="noopener noreferrer" draggable="false"
    className="inline-block text-white mt-8 btn text-[20px] px-[22px] py-[11px] hover:scale-98 active:scale-95 noselect"><span
    style={{color: 'white'}}>Start now</span></a>
  </HeadingPrimaryStyled>);
}
