import Header from '../components/Header'
import Footer from '../components/Footer'
import TryNow from "../components/TryNow";
import TwitterGalleryMobile from "../components/TwitterGalleryMobile";
import TwitterGalleryDesktop from "../components/TwitterGalleryDesktop";
import Features from "../components/Features";
import NewsLetter from "../components/NewsLetter";
import ProductShowCase from "../components/ProductShowCase";
import CommonHead from "../components/CommonHead";
import HeadLine from "../components/HeadLine";
import styled from "styled-components";

const Bar = styled.div`
  position: sticky;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  padding: 0px 20px;
  top: 0px;
  z-index: 30;

  a {
    display: inline-block;
    color: white;
    border-bottom: thin solid transparent;
  }

  &:hover a {
    border-color: white;
  }

  .link_wrapper {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    line-height: 23px;
  }

  svg {
    margin-left: 10px;
    fill-opacity: 0.5;
  }

  .iqrnry {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    padding: 10px;
    svg {
      fill-opacity: 0.5;
    }
  }

  @media (max-width: 480px) {
    -webkit-box-pack: start;
    justify-content: flex-start;
    .link_wrapper {
      font-size: 16px;
    }
  }

`

export default function Home() {
  return (
    <div className={`antialiased`}>
      <Bar className="bg-ph-purple">
        <div className="link_wrapper"><a>We have updated our Terms of Service</a>
          <svg width="14.5" height="14" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM0 9H16V7H0V9Z"
              fill="white"/>
          </svg>
        </div>
        <button className="iqrnry">
          <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 10.6811L7.11455 5.80495L11.6006 1.31889L10.291 0L5.80495 4.48607L1.31889 0L0 1.31889L4.48607 5.80495L0 10.291L1.31889 11.6006L5.80495 7.11455L10.6811 12L12 10.6811Z"
              fill="white"/>
          </svg>
        </button>
      </Bar>
      <CommonHead/>
      <Header/>
      <main className="max-w-[1210px] mx-auto">
        <HeadLine/>
        <ProductShowCase/>
        <Features/>
        <NewsLetter/>
        <TwitterGalleryDesktop/>
        <TwitterGalleryMobile/>
        <div className="mt-6 md:mt-[102px]">
          <TryNow/>
        </div>

      </main>

      <Footer/>

    </div>
  )
}
